
import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  CardContent,
  Card,
  Snackbar,
  Alert as MuiAlert,
  LinearProgress,
} from "@mui/material";
import { apigetMachine } from "../api/apigetmachine";
import { apigetLines } from "../api/api.getline";
import { apiMachinePerformance } from "../api/api.MachinePerformance";
import { useAuthCheck } from "../utils/Auth";
import "../assets/css/forms.css";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
const getCurrentDateHour = () => {
  const now = new Date();
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}T${String(now.getHours()).padStart(2, '0')}:00`;
};

const getBackgroundColor = (value, type) => {
  if (type === "percentage") {
    value = parseFloat(value);
    if (value >= 75) return "darkgreen";
    if (value >= 50) return "yellow";
    return "red";
  } else {
    return value.toLowerCase();
  }
};

const StyledCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #ededed 30%, #ffffff 90%)",
  // backdropFilter: 'blur(10px)',
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));

const MetricBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.2)",
  borderRadius: "10px",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const getColor = (value) => {
  value = parseFloat(value);
  if (value >= 75) return "#4caf50";
  if (value >= 50) return "#ff9800";
  return "#f44336";
};

const MachineCard = ({ machine }) => {
  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: "bold", color: "#1a237e" }}
        >
          {machine.machineName}
        </Typography>
        <Grid container spacing={2}>
          {[
            "Availability",
            "Performance",
            "Quality",
            "OEE",
            "OPE",
            "Utilization",
          ].map((metric) => (
            <Grid item xs={4} key={metric}>
              <MetricBox
                style={{
                  backgroundColor: getColor(
                    machine[`${metric.toLowerCase()}Percentage`]
                  ),
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {metric}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    backgroundColor: getColor(
                      machine[`${metric.toLowerCase()}Percentage`]
                    ),
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {machine[`${metric.toLowerCase()}Percentage`]}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={parseFloat(
                    machine[`${metric.toLowerCase()}Percentage`]
                  )}
                  style={{
                    height: 5,
                    borderRadius: 5,
                    backgroundColor: "rgba(0,0,0,0.1)",
                    color: getColor(
                      machine[`${metric.toLowerCase()}Percentage`]
                    ),
                  }}
                />
              </MetricBox>
            </Grid>
          ))}
        </Grid>
        <Box
          mt={2}
          sx={{
            border: "1px solid black",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            Total Time: {machine.totalTime}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            Utilized Time: {machine.utilizedTime}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            Down Time: {machine.downTime}
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default function IconicDashboard() {
  const [lineData, setLineData] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [machineData, setMachineData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [showCards, setShowCards] = useState(false);

  const [rawData, setRawData] = useState({
    lineNo: "",
    fromDate: getCurrentDateHour(),
    toDate: getCurrentDateHour(),
  });
  useAuthCheck();

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        setLineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, []);

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    let formattedValue = value;
    
    if (name === 'fromDate' || name === 'toDate') {
      // For date inputs, ensure we only keep the date and hour
      formattedValue = value.slice(0, 13) + ":00";
    }
    
    setRawData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    return `${String(date.getDate()).padStart(2, "0")}-${
      months[date.getMonth()]
    }-${date.getFullYear()} ${String(date.getHours()).padStart(2, "0")}:00:00`;
  };
  const handleOkClick = async () => {
    try {
      const performanceResult = await apiMachinePerformance({
        lineNo: rawData.lineNo,
        fromDate: formatDate(rawData.fromDate),
        toDate: formatDate(rawData.toDate),
      });
      console.log("machine performance data:", performanceResult.data);
      setMachineData(performanceResult.data);
      setShowCards(true);
      handleSnackbarOpen("Data fetched successfully", "success");
    } catch (error) {
      console.error("Error in handleOkClick:", error);
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
      setShowCards(false);
    }
  };

  return (
    <div style={{ padding: "0px 20px", width: "100%", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px 0px 20px 0px",
        }}
      >
        <h2>Machine Performance </h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "flex-start",
        }}
      >
        <Grid item xs={3} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={rawData.lineNo}
              onChange={handleInputChange}
            >
              {lineData.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date & Time"
              name="fromDate"
              type="datetime-local"
              value={rawData.fromDate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 3600, 
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date & Time"
              name="toDate"
              type="datetime-local"
              value={rawData.toDate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 3600, 
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleOkClick}>
            OK
          </Button>
        </Grid>
      </Grid>

      {showCards && (
        <Grid container spacing={2}>
          {machineData.length === 0 ? (
            <Typography variant="h6">
              No machines found for the selected plant.
            </Typography>
          ) : (
            machineData.map((machine) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={machine.machineId}
                style={{ marginBottom: "40px" }}
              >
                <MachineCard machine={machine} />
              </Grid>
            ))
          )}
        </Grid>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
